// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/pic_news_bottom@2x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".content[data-v-7d614ebf]{overflow:hidden}[data-v-7d614ebf] .van-hairline--bottom:after{border-bottom:none}[data-v-7d614ebf] .van-nav-bar__content{height:12vw}[data-v-7d614ebf] .van-nav-bar__content .van-nav-bar__left .van-icon{font-size:5.333vw;color:#2a2a2a}.newsTitle[data-v-7d614ebf]{width:100%;padding:0 3.2vw 0 2.667vw}.newsTitle h1[data-v-7d614ebf]{width:100%;padding-right:2.667vw;font-size:5.867vw;font-family:PingFang SC-Medium,PingFang SC;font-weight:600;color:#1a1a1a;line-height:7.733vw;letter-spacing:.267vw;text-align:center}.newsTitle .info[data-v-7d614ebf]{display:-webkit-box;display:-ms-flexbox;display:flex;margin-top:2.667vw;margin-bottom:5.333vw;width:100%}.newsTitle .info span[data-v-7d614ebf]{font-size:1.6vw;font-family:PingFang SC-Regular,PingFang SC;font-weight:400;color:#999;line-height:7.467vw}.newsTitle .info .leftText[data-v-7d614ebf]{width:13.333vw;margin-left:1.333vw}.newsTitle .info .leftText span[data-v-7d614ebf]{font-size:3.2vw}.newsTitle .info .rightText[data-v-7d614ebf]{width:100%;text-align:right}.newsTitle .info .rightText span[data-v-7d614ebf]{font-size:2.933vw}.newsBtm[data-v-7d614ebf]{width:100%;height:80vw;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%}[data-v-7d614ebf] .van-popup{height:37.333vw}[data-v-7d614ebf] .van-popup .van-share-sheet__header{padding:2.133vw 11.333vw 1.6vw}[data-v-7d614ebf] .van-popup .van-share-sheet__header .van-share-sheet__title{font-size:4.533vw}[data-v-7d614ebf] .van-popup .van-share-sheet__option{width:26.667vw;padding:.8vw 0 .533vw 1.333vw}[data-v-7d614ebf] .van-popup .van-share-sheet__cancel{font-size:4vw;line-height:9.333vw;margin-top:1.867vw}.contentTxt[data-v-7d614ebf]{width:100%;padding:0 3.467vw;font-size:3.733vw}.contentTxt[data-v-7d614ebf],.contentTxt p[data-v-7d614ebf]{overflow:hidden}", ""]);
// Exports
module.exports = exports;
