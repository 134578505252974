var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("van-nav-bar", {
        attrs: {
          "left-arrow": "",
          fixed: "",
          placeholder: true,
          "safe-area-inset-top": ""
        },
        on: { "click-left": _vm.onClickLeft },
        scopedSlots: _vm._u([
          {
            key: "right",
            fn: function() {
              return undefined
            },
            proxy: true
          }
        ])
      }),
      _c("div", { staticClass: "newsTitle" }, [
        _c("h1", [_vm._v(_vm._s(_vm.title))]),
        _c(
          "div",
          { staticClass: "info" },
          [
            _c("van-image", {
              attrs: {
                width: "34",
                height: "28",
                src: require("@/assets/images/pic_news_ avatar@2x.png")
              }
            }),
            _c("div", { staticClass: "leftText" }, [
              _c("span", [_vm._v(_vm._s(_vm.GLOBAL))])
            ]),
            _c("div", { staticClass: "rightText" }, [
              _c("span", [_vm._v(_vm._s(_vm._f("formatDate_")(_vm.newTime)))])
            ])
          ],
          1
        )
      ]),
      _c("div", {
        staticClass: "contentTxt",
        domProps: { innerHTML: _vm._s(_vm.content) }
      }),
      _c("div", { staticClass: "newsBtm" }),
      _c("van-share-sheet", {
        attrs: { title: "立即分享给好友", options: _vm.options },
        model: {
          value: _vm.showShare,
          callback: function($$v) {
            _vm.showShare = $$v
          },
          expression: "showShare"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }