<template>
  <div class="content">
      <!-- 头部导航栏   -->
        <van-nav-bar  left-arrow fixed :placeholder="true" @click-left="onClickLeft" safe-area-inset-top>
            <template #right>

                    <!-- 分享图标 -->
                    <!-- <van-image
                     width="18"
                     height="18"
                    :src="require('@/assets/images/btn_share.png')"
                    @click="showShare = true"
                     /> -->
            </template>
        </van-nav-bar>
      <!-- 新闻标题 -->
      <div class="newsTitle">
              <h1>{{title}}</h1>
              <div class="info">
                       <van-image
                        width="34"
                        height="28"
                        :src="require('@/assets/images/pic_news_ avatar@2x.png')"
                     />
                      <div class="leftText"><span>{{GLOBAL}}</span></div>
                      <div class="rightText"><span>{{newTime | formatDate_}}</span></div>
                      
              </div>
      </div>
      <div class="contentTxt" v-html="content">
          
      </div>
      <!-- 新闻底部 -->
      <div class="newsBtm"></div>
      <!-- 分享面板 -->
      <van-share-sheet
        v-model="showShare"
        title="立即分享给好友"
        :options="options"
        />
  </div>
</template>

<script>
export default {
        data() {
            return {
                // 新闻数据
                newList:{},
                // 数据内容
                content:'',
                // 新闻标题
                title:'',
                // 时间
                newTime:'',
                // 分享面板的隐藏与显示
                showShare:false,
                options: [
                    { name: '微信', icon: 'wechat' },
                    { name: '微博', icon: 'weibo' },
                    { name: 'QQ', icon: 'qq' },
                    { name: '复制链接', icon: 'link' },
                    ],
            }
        },
        methods:{
            onClickLeft(){
                this.$router.back()
            }
        },
        created(){
            this.newList = JSON.parse( localStorage.getItem('newContent'))
            console.log(this.newList);
            this.content = this.newList.content
            this.title = this.newList.title
            this.newTime = this.newList.created
        }
}
</script>

<style lang="scss" scoped>
.content{
    overflow: hidden;
}
::v-deep{
    .van-hairline--bottom:after{
    border-bottom:none
}
.van-nav-bar__content{
        height: 90px;
         .van-nav-bar__left{
             .van-icon{
                 font-size: 40px;
                 color: #2a2a2a;
             }
         }
}
}
.newsTitle{
    // position: fixed;
    // top: 90px;
    // left: 0;
    // right: 0;
    // z-index: 666;
    // border-bottom: 1px solid transparent;
    width: 100%;
    padding: 0 24px 0 20px;
    h1{
        width: 100%;
        padding-right: 20px;
        font-size: 44px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 600;
        color: #1A1A1A;
        line-height: 58px;
        letter-spacing:2px;
        text-align: center;
    }
    .info{
        display: flex;
        margin-top: 20px;
        margin-bottom: 40px;
        width: 100%;
        span{
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 56px;
        }
        .leftText{
            width: 100px;
            margin-left: 10px;
            span{
                font-size: 24px;
            }
            
        }
        .rightText{
            width: 100%;
            text-align: right;
            span{
                font-size: 22px;
            }
        }
    }
}
.newsBtm{
    // position: fixed;
    // bottom: 0;
    // left: 0;
    width: 100%;
    height: 600px;
    background: url("~@/assets/images/pic_news_bottom@2x.png") no-repeat;
    background-size: 100% 100%;
}
::v-deep{
    .van-popup{
    height: 280px;
    .van-share-sheet__header{
        padding: 16px 85px 12px;
        .van-share-sheet__title{
            font-size: 34px;
        }
    }
    .van-share-sheet__option {
         width: 200px;
         padding: 6px 0 4px 10px;
    }
    .van-share-sheet__cancel{
        font-size: 30px;
        line-height: 70px;
        margin-top: 14px;
    }
}
}
.contentTxt{
    width: 100%;
    padding: 0 26px;
    font-size: 28px;
    overflow: hidden;
    p{
        overflow: hidden;
    }
}
</style>